/*
    Base LD Variations
 */
export const CONTROL = 'control';
export const TREATMENT = 'treatment';
export const NONE = 'none';

/*
    Experiment Flags
 */

export const FDT_285 = 'fdt-285-account-creation';
export const FDT_285_DISPLAY_PLANS = 'fdt-285-display-plans';
export const FDT_387 = 'fdt-387-free-plan';
